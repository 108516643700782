import * as React from "react";
import { useState } from "react";
import Label from "../Label/Label";
import styles from "./ContractView.module.css";

export interface IContractViewProps {
   totalInvestment: string;
   totalUsers: string;
   registered: boolean;
}

export const ContractView: React.FC<IContractViewProps> = (props) => {
   const [amount] = useState("");
   const [, setLabel] = useState("");

   React.useEffect(() => {
      async function run() {
         setLabel("0");
      }
      if (amount && parseFloat(amount) > 0) run();
      else setLabel("");
   }, [amount]);

   return (
      <div className={`box ${styles.wrap}`}>
         {/* <li>{props.userMiners}</li> */}
         {/* <Label
            label="CONTRACT BALANCE"
            value={props.totalInvestment}
            unit="BNB"
         /> */}
         {/* <Label label="Total Users" value={props.totalUsers} /> */}
         {/* <li>{props.userDividends}</li> */}
         {/* <li>{props.marketNewtella}</li>
            <li>{props.contractBalance}</li> */}
      </div>
   );
};
