import { ethers } from "ethers";
import abi from "./api.json";

export const contractAddress = "0x4ef1dCFDcF8F4B99deBa2567c4110B06b649Ae0f"; //"0xae6f96754A231a76352ebD6f382B24800f2818bf";

export const getContract = () => {
   const provider = new ethers.providers.Web3Provider((window as any).ethereum);
   const signer = provider.getSigner();

   const contract = new ethers.Contract(contractAddress, abi as any, signer);

   return contract;
};

export const reinvest = (callback?: () => void) => {
   const contract = getContract();
   contract.reinvest({
      gasLimit: 10000000,
   });
   contract.on("reinvestEvent", async () => {
      callback && callback();
   });
};

export const withdraw = (callback?: () => void) => {
   const contract = getContract();
   contract.withdraw({
      gasLimit: 10000000,
   });
   callback &&
      contract.on("sellEvent", async () => {
         callback();
      });
};

export const deposit = (
   referrer: string,
   amount: string,
   callback?: () => void
) => {
   const contract = getContract();
   contract.deposit(referrer, {
      value: ethers.utils.parseEther(amount),
      gasLimit: 10000000,
   });
   contract.on("buyEvent", async () => {
      callback && callback();
   });
};

export const getContractData = async (userAddress: string | null) => {
   const contract = getContract();
   const data = await contract.getContractData(userAddress);
   return data;
};
