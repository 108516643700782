import styles from "./ReferralView.module.css";
import { Button } from "../Button/Button";
import copy from "copy-to-clipboard";
import { useWeb3React } from "@web3-react/core";
import Label from "../Label/Label";
import { HORUS } from "../../utils/env";

const baseUrl = "https://wadjetegyptianminer.com";

export const ReferralView = ({
   refBonus,
   refsCount,
}: {
   refBonus: string;
   refsCount: string[];
   totalInvestment: string;
}) => {
   const { account } = useWeb3React();
   function handleClick() {
      if (account) {
         copy(`${baseUrl}?ref=${account}`);
         alert("Your link copied successfully");
      }
   }
   return (
      <div className={`${styles.wrap}`}>
         <p>
            There are 4 levels of referral bonus. 5%, 3%, 2%, 2%. The referral
            bonus will paid on deposit. Referrals Commission will be added as
            HORUSs to your TVL. If you withdraw you referral TVL will be reset.
         </p>
         <Label
            label="TOTAL REFERRAL"
            value={(parseFloat(refBonus) * HORUS).toFixed(2)}
            unit="HORUS"
         />
         <Label label="LEVEL 1" value={refsCount[0]} />
         <Label label="LEVEL 2" value={refsCount[1]} />
         <Label label="LEVEL 3" value={refsCount[2]} />
         <Label label="LEVEL 4" value={refsCount[3]} />
         <div className={styles.tools}>
            <Button bgColor="red" onClick={handleClick}>
               Copy My Link
            </Button>
         </div>
      </div>
   );
};
