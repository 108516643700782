import { useState } from "react";
import { reinvest, withdraw } from "../../utils/contract";
import { HORUS } from "../../utils/env";
import { Button } from "../Button/Button";
import Label from "../Label/Label";
import styles from "./UserView.module.css";

export interface IUserViewProps {
   investment: string;
   dividends: string;
   deposit: string;
   rate: string;
   withdrawal: string;
   reinvests: string;
   canWithdraw: string;
}

export const UserView = ({
   investment,
   dividends,
   deposit,
   rate,
   withdrawal,
   reinvests,
   canWithdraw,
}: IUserViewProps) => {
   const [horus, setHorus] = useState("1");
   return (
      <div className={`${styles["card-wrapper"]}`}>
         <div className={styles.calc}>
            <div>
               <div>HORUS</div>
               <input
                  type="number"
                  placeholder="HORUS"
                  value={horus}
                  onChange={(e) => setHorus(e.target.value)}
               />
            </div>
            <span> = </span>
            <div>
               <div>BNB</div>
               <input
                  type="number"
                  placeholder="BNB"
                  value={+horus / 1000}
                  onChange={(e) =>
                     setHorus((+e.target.value * 1000).toString())
                  }
               />
            </div>
         </div>
         <br />
         <Label
            label="Investment"
            value={(parseFloat(investment) * HORUS).toFixed(1)}
            unit="HORUS"
         />
         <Label
            label="Dividends"
            value={(parseFloat(dividends) * HORUS).toFixed(2)}
            unit="HORUS"
         />
         <Label
            label="TVL"
            value={(parseFloat(deposit) * 1000).toFixed(2)}
            unit="HORUS"
         />
         <Label label="Rate" value={rate} />
         {/* <Label label="Can Withdraw" value={canWithdraw} /> */}
         <Label label="withdrawal" value={withdrawal} unit="BNB" />
         {/* <div className={styles.total}>{grogBNB}</div> */}
         {parseFloat(investment) > 0 && (
            <div>
               <div className={styles.tools}>
                  {canWithdraw ? (
                     <Button onClick={() => withdraw()} bgColor="green">
                        FALCON
                     </Button>
                  ) : null}
                  <Button
                     onClick={() => {
                        reinvest();
                     }}
                     bgColor="red"
                  >
                     CAT
                  </Button>
               </div>
            </div>
         )}
      </div>
   );
};
