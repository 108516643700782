import { reinvest } from "../../utils/contract";
import { dailyTimeString, DAY, isReset } from "../../utils/time_util";
import { Button } from "../Button/Button";
import { Timer } from "../Timer/Timer";
import styles from "./CycleIndicator.module.css";
function cycleDay(checkpoint: number) {
   return Math.floor((new Date().getTime() / 1000 - checkpoint) / DAY) % 21;
}
function dayStatus(day: number, checkpoint: number, hatches: number) {
   return day > cycleDay(checkpoint)
      ? "upcoming"
      : day === cycleDay(checkpoint) && ((2 ** day) & hatches) !== 2 ** day
      ? "current"
      : ((2 ** day) & hatches) === 2 ** day
      ? "done"
      : "failed";
}
const names = [
   "SLAVE",
   "CAPTIVE",
   "FARMER",
   "WORKER",
   "ARTISAN",
   "MERCHANT",
   "DOCTOR",
   "APPRENTICE SCRIBE",
   "SCRIBE",
   "MASTER SCRIBE",
   "WARRIOR",
   "CHIEF WARRIOR",
   "NOBLE",
   "DIGNITARY",
   "GRAND DIGNITARY",
   "JUDGE",
   "HIGHER JUDGE",
   "PRIEST",
   "HIGH PRIEST",
   "PHARAOH",
];
export function CycleIndicator({
   hatches,
   checkpoint,
   reinvestCheckPoint,
}: {
   hatches: number;
   checkpoint: number;
   reinvestCheckPoint: number;
}) {
   return isReset(hatches, checkpoint, reinvestCheckPoint) ? (
      <div className={styles.form}>
         <span>
            Your PYRAMID is disabled. Please click ENABLE to activate it.
         </span>
         <Button onClick={() => reinvest()}>ENABLE</Button>
      </div>
   ) : (
      <div>
         <div className={styles.wrap}>
            <div
               className={`${styles.s21} ${
                  cycleDay(checkpoint) === 20 ? styles.shine : ""
               }`}
            ></div>
            {names.map((_, i) => (
               <div
                  key={i}
                  className={`${styles.s} ${styles["s" + (19 - i)]} ${
                     dayStatus(19 - i, checkpoint, hatches) === "failed"
                        ? styles.failed
                        : dayStatus(19 - i, checkpoint, hatches) === "current"
                        ? styles.active
                        : dayStatus(19 - i, checkpoint, hatches) === "done"
                        ? styles.done
                        : styles.upcoming
                  }`}
               >
                  {names[19 - i]}
               </div>
            ))}
         </div>
         <Timer
            timeStr={dailyTimeString(
               DAY - ((new Date().getTime() / 1000 - checkpoint) % DAY)
            )}
         />
      </div>
   );
}
