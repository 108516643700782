/* eslint-disable jsx-a11y/anchor-is-valid */
import { contractAddress } from "../../utils/contract";
import { Button } from "../Button/Button";
import styles from "./Nav.module.css";

export const Nav = () => {
   return (
      <header>
         <div className={styles.logo}>
            <div className={styles.buttons}>
               <Button href="https://docs.google.com/gview?embedded=true&url=https://wadjetegyptianminer.com/audit.pdf">
                  Audit
               </Button>
               <Button
                  href={`https://www.bscscan.com/address/${contractAddress}`}
               >
                  Smart Contract
               </Button>
            </div>
         </div>
         <div className={styles.social}></div>
      </header>
   );
};
