import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { useCallback, useEffect, useState } from "react";
import "./App.css";
import { Header } from "./components/Header/Header";
import { UserView } from "./components/UserView/UserView";
import { ReferralView } from "./components/ReferralView/ReferralView";
import { ContractView } from "./components/ContractView/ContractView";
import { injected } from "./utils/connector";
import { deposit, contractAddress, getContractData } from "./utils/contract";
import Box from "./components/Box/Box";
import Links from "./components/Links/Links";
import { Button } from "./components/Button/Button";
import { Help } from "./components/Help/Help";
import { CycleIndicator } from "./components/CycleIndicator/CycleIndicator";
import { getRef } from "./utils/env";
import { ItemSelectList } from "./components/ItemSelect/Index";
import { DAY, days, isReset } from "./utils/time_util";
import { DayCounter } from "./components/DayCounter/DayCounter";
import Images from "./images";

function handleBuy(amount: string) {
   if (!getRef()) {
      alert("Please use an invitation link");
      return;
   }
   if (parseFloat(amount) > 0) deposit(getRef(), amount, () => {});
}

function App() {
   const { active, account, activate } = useWeb3React();
   const [stats, setStats] = useState({
      investment: "---",
      dividends: "---",
      deposit: "---",
      rate: "---",
      refIncome: "---",
      withdrawal: "0",
      reinvestCheckPoint: "---",
      withdrawCheckPoint: "---",
      reinvests: "---",
      canWithdraw: "---",
      contractBalance: "---",
      totalInvestment: "0",
      totalUsers: "0",
      refsCount: ["0", "0", "0", "0"],
   });
   // const [count, setCount] = useState("0");
   const [showModal, setShowModal] = useState(false);
   // const audioRef = createRef<HTMLAudioElement>();
   const [showWelcome, setShowWelcome] = useState(true);
   const [tradingModal, setTradingModal] = useState(false);

   const loadData = useCallback(
      async function loadData() {
         if (account) {
            const data = await getContractData(account);
            setStats({
               investment: ethers.utils.formatEther(data[0]).substring(0, 5),
               dividends: ethers.utils.formatEther(data[1]).substring(0, 6),
               deposit: ethers.utils.formatEther(data[2]).substring(0, 5),
               rate: (data[3] / 10).toString(),
               refIncome: ethers.utils.formatEther(data[4]).substring(0, 5),
               withdrawal: ethers.utils.formatEther(data[5]).substring(0, 5),
               reinvestCheckPoint: data[6].toNumber(),
               withdrawCheckPoint: data[7].toNumber(),
               reinvests:
                  data[6].toNumber() <
                  data[7].toNumber() +
                     21 * DAY * Math.floor(days(new Date().getTime() / 1000 - data[7]) / 21)
                     ? "0"
                     : ((2 ** 20 - 1) & data[8]).toFixed(0),
               canWithdraw: data[9].toNumber(),
               contractBalance: ethers.utils.formatEther(data[10]).substring(0, 5),
               totalInvestment: ethers.utils.formatEther(data[11]).substring(0, 5),
               totalUsers: data[12].toNumber(),
               refsCount: [
                  data[13].toNumber(),
                  data[14].toNumber(),
                  data[15].toNumber(),
                  data[16].toNumber(),
               ],
            });
         }
      },
      [account]
   );

   // function onEnter() {
   //    setShowWelcome(false);
   //    audioRef.current?.play();
   // }

   useEffect(() => {
      activate(injected);
      if (active) {
         loadData();
      }
   }, [activate, active, loadData]);

   useEffect(() => {
      const interval = window.setInterval(() => {
         loadData();
      }, 1000);
      return () => clearInterval(interval);
   }, [loadData]);

   // function onEnter() {
   //    setShowWelcome(false);
   //    audioRef.current?.play();
   // }
   // const interval = useRef(0);

   // useEffect(() => {
   //    interval.current = window.setInterval(function () {
   //       var now = new Date().getTime();

   //       // Find the distance between now and the count down date
   //       var distance = new Date(Date.UTC(2022, 5, 1, 16)).getTime() - now;

   //       // Time calculations for days, hours, minutes and seconds
   //       var days = Math.floor(distance / (1000 * 60 * 60 * 24));
   //       var hours = Math.floor(
   //          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
   //       );
   //       var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
   //       var seconds = Math.floor((distance % (1000 * 60)) / 1000);

   //       setCount(days + "d " + hours + "h " + minutes + "m " + seconds + "s ");

   //       return () => clearInterval(interval.current);
   //    }, 1000);
   // }, []);
   return (
      <div className="main-wrap">
         {/* <Landing ref={audioRef} /> */}
         {/* <Nav /> */}

         <div className="landing">
            <Header />
         </div>
         <div className="presale">
            <a href="https://bmptoken.com" className="link" target="_blank" rel="noreferrer">
               BUY BMP TOKEN
            </a>
         </div>
         <div className="trading">
            <img src={Images.blinking} alt="Trading live opportunities" />
            <a
               href="#"
               onClick={(e) => {
                  e.preventDefault();
                  setTradingModal(true);
               }}
            >
               <p>
                  <span>TRADING</span> Opportunity...
               </p>
            </a>
         </div>
         <Help showHelp={tradingModal} onClose={() => setTradingModal(false)}>
            <div>
               <h1>Opportunity 💵💵💵💵</h1>
               <h4>Extra income BMP brand 🚨</h4>
               <p>2 options available 💰</p>
               <p>Option 1👇👇</p>
               <p>Requirements ☀️</p>
               <p>1👉Have at least 1000 tokens in the wallet 🚨 </p>
               <p>
                  2👉 Have an active Word cup deposit and mandatory participation in all future
                  brand developments 🚨
               </p>
               <p>
                  Always and only solely from the wallet where you hold the tokens ( another wallet
                  will not be valid to get the trading commissions)
               </p>
               <p>Option 2</p>
               <p>1👉Have in your wallet at least 700 tokens 🚨🚨🚨</p>
               <p>
                  2👉Have an active deposit in all brand dapps created (BnB pirates, fast furius,
                  paper house, wadjet egyptian miner, World cup) All exclusively in the wallet where
                  you hold the tokens no other wallets than that are accepted participate in all
                  future developments
               </p>
               <p>What will you gain?💵💰💵💰💵💰💵💰</p>
               <p>
                  Each month liquidity will be added in trading and each month 10% of trading
                  earnings will be calculated and divided to all eligible people who filled out the
                  form Payment will be made in busd in the wallet where you hold the
                  token💵💵💵💵💵💵
               </p>
               FOR INFORMATION WRITE TO @Silene_Oliveira_TPH @Mikecryptobusiness
               <br />
               remember to fill out the form👇👇👇👇👇
               <a href="https://forms.gle/nsd2ZDmqgtm898MPA">Google Form</a>
            </div>
         </Help>
         <DayCounter start={1657892476} />
         <section className="contract">
            <div className="header"></div>
            <div className="body">
               <p>
                  Enter in the magical world of pharaohs and Egyptian gods, choose your patron deity
                  and start lighting up your pyramid.
               </p>
               <p>
                  40% automatic reinvest makes the balance sustainable. Deposit and enjoy the
                  profit.
               </p>
               <p>Very safe plan implemented in smart contract.</p>

               <ContractView
                  totalUsers={stats.totalUsers}
                  totalInvestment={stats.contractBalance}
                  registered={parseFloat(stats.investment) > 0}
               />
               {!active && (
                  <div className="center">
                     <Button full onClick={() => activate(injected)}>
                        Connect
                     </Button>
                  </div>
               )}
            </div>
         </section>
         {parseFloat(stats.investment) > 0 && (
            <section className="pyramid">
               <CycleIndicator
                  checkpoint={parseInt(stats.withdrawCheckPoint)}
                  hatches={parseInt(stats.reinvests)}
                  reinvestCheckPoint={parseInt(stats.reinvestCheckPoint)}
               />
            </section>
         )}
         <section className="buy">
            <ItemSelectList submitHandler={handleBuy} />
         </section>
         <section className="stats">
            <div className="header"></div>
            <div className="body">
               <UserView
                  investment={stats.investment}
                  deposit={
                     isReset(
                        parseInt(stats.reinvests),
                        parseInt(stats.withdrawCheckPoint),
                        parseInt(stats.reinvestCheckPoint)
                     )
                        ? "0"
                        : stats.deposit
                  }
                  dividends={
                     isReset(
                        parseInt(stats.reinvests),
                        parseInt(stats.withdrawCheckPoint),
                        parseInt(stats.reinvestCheckPoint)
                     )
                        ? "0"
                        : stats.dividends
                  }
                  rate={
                     isReset(
                        parseInt(stats.reinvests),
                        parseInt(stats.withdrawCheckPoint),
                        parseInt(stats.reinvestCheckPoint)
                     )
                        ? "0"
                        : stats.rate
                  }
                  withdrawal={stats.withdrawal}
                  reinvests={
                     isReset(
                        parseInt(stats.reinvests),
                        parseInt(stats.withdrawCheckPoint),
                        parseInt(stats.reinvestCheckPoint)
                     )
                        ? "0"
                        : stats.reinvests
                  }
                  canWithdraw={stats.canWithdraw}
               />
            </div>
         </section>
         <section className="referral">
            <div className="header"></div>
            <div className="body">
               <ReferralView
                  refBonus={stats.refIncome}
                  refsCount={stats.refsCount}
                  totalInvestment={stats.totalInvestment}
               />
            </div>
         </section>

         <Box header="CAT is Mandatory">
            <p>
               If the CAT button (reinvest button) isn't made use of every day for 20 consecutive
               days then you cannot withdraw on the 21th day. If you miss clicking the FALCON button
               for a day its not possible to withdraw on the 21th day. You have to again start
               clicking the CAT button after the 21 days and you have to do it everyday for another
               20 days so you can withdraw on the 2nd cycle which is 42 days or else you have to use
               this process for the 3rd cycle.
            </p>
            <p>
               <b>Note:</b> This is is coded in the smart contract for longevity and safety of the
               investors.
            </p>
            <Links />
         </Box>
         {
            <Help
               showHelp={showWelcome}
               onClose={() => {
                  setShowWelcome(false);
                  setShowModal(true);
               }}
            >
               <div>
                  <h4>📰 BMP BRAND NEWS 📰</h4>
                  <p>
                     ✔️ Signed partnership agreement with one of the world's largest brokers TOP FX.
                     This will generate the first profits and will bring new liquidity to all our
                     Dapps.
                  </p>
                  <p>✔️ Also shortly there will be the launch of the next dapp.</p>

                  <p>✔️ Opportunity Contest still active.</p>

                  <p>
                     🤔 For info on how to participate, join our official channel or send a private
                     message to 📱 @Silene_Oliveira_TPH
                  </p>

                  <p>
                     <a href="https://t.me/BMPBrandDDB">📱OFFICIAL CHANNEL</a>
                  </p>
                  <h4>💪 BMP BRAND CHOOSE THE FUTURE IN DAPP. THE BMP BRAND IS HERE NOW‼</h4>
               </div>
            </Help>
         }
         <Help showHelp={showModal} onClose={() => setShowModal(false)}>
            <div>
               <h4>🕶👍VIP CONTEST 👍🕶</h4>

               <p>STARTING FROM 5th DECEMBER YOU WILL HAVE 2 chances to join in the VIP👑 group:</p>

               <p>🔅 Limited pass 🎟</p>
               <p>🔆 Unlimited Pass 🎫</p>

               <h4>🔩 GAME RULES 🎮</h4>

               <p>
                  PASS LIMITED 🎟 to stay until the launch and get the world preview link along with
                  the other group benefits of the next dapp.
               </p>
               <p>➡️ Deposit 0.5 Bnb or more in 2 any Dapp (0.5+0.5) of the brand or</p>
               <p>➡️ Deposit 1 BnB or more in any one Dapp.</p>

               <p>UNLIMITED PASS 🎫 to the VIP group 👑 to stay forever.</p>

               <p>➡️ Deposit 2 BnB or more in 2 Dapps (1+1) of the brand.</p>

               <p>➡️ Deposit 2 BnB or more in any Dapp.</p>

               <p>
                  ☁️ FOR INFO Contact{" "}
                  <a href="https://t.me/Silene_Oliveira_TPH">@Silene_Oliveira_TPH</a>
               </p>
               <p>
                  <a href="https://forms.gle/xthCHevNqYVrMedc9">
                     ✍️ FILL OUT THE FORM to be added‼️
                  </a>
               </p>

               <p>
                  {" "}
                  🆘 NB. THE CONTEST IS NOT RETROACTIVE. No deposits prior to the launch date are
                  allowed.
               </p>

               <p>💎💎💎💎💎💎💎💎💎💎💎</p>

               <h4>🕶👍VIP CONTEST 👍🕶</h4>

               <p>A PARTIRE DAL 5 DICEMBRE AVRAI 2 possibilità per entrare nel gruppo Vip👑:</p>

               <p>🔅 Pass limitato 🎟</p>
               <p>🔆 Pass Illimitato 🎫</p>

               <h4>🔩 REGOLE DI GIOCO 🎮</h4>

               <p>
                  PASS LIMITATO 🎟 per rimanere fino al lancio e ottenere il link in anteprima
                  mondiale insieme agli altri benefici del gruppo, della prossima dapp uscente.
               </p>

               <p> ➡️ Deposita 0.5 Bnb o più in 2 qualsiasi Dapp (0.5+0.5) del brand o</p>

               <p> ➡️ Deposita 1 BnB o più in una qualsiasi Dapp.</p>

               <p>PASS ILLIMITATO 🎫 al gruppo Vip👑 per rimanere per sempre.</p>

               <p>➡️ Deposita 2 Bnb o più in 2 Dapp (1+1) del brand.</p>

               <p>➡️ Deposita 2 BnB o più in una qualsiasi Dapp.</p>

               <p>💎 GRUPPO VIP👑 IL MASSIMO</p>

               <p>
                  ☁️ PER INFO Contatta{" "}
                  <a href="https://t.me/Silene_Oliveira_TPH">@Silene_Oliveira_TPH</a>
               </p>
               <p>
                  <a href="https://forms.gle/xthCHevNqYVrMedc9">
                     ✍️ COMPILA IL FORM per essere aggiunto‼️
                  </a>
               </p>

               <p>
                  🆘 NB. IL CONTEST NON È RETROATTIVO. Non sono ammessi depositi precedenti la data
                  di lancio.
               </p>
            </div>
         </Help>
         {/* <Footer /> */}
         {/* {showWelcome ? <Welcome hide={() => onEnter()} /> : null} */}
         {/* <Audio ref={audioRef} /> */}
      </div>
   );
}

export default App;
