import bsc from "./assets/bsc.png";
import bnb from "./assets/bnb.png";
import jotform from "./assets/jotform.png";
import help from "./assets/help.png";
import linktee from "./assets/linktee.png";
import tel from "./assets/tel.png";
import amon from "./assets/amon.jpg";
import osiride from "./assets/osiride.jpg";
import iside from "./assets/iside.jpg";
import hathor from "./assets/hathor.jpg";
import logo from "./assets/logo.png";
import blinking from "./assets/blinking_new.gif";

const Images = {
   bsc,
   bnb,
   jotform,
   help,
   linktee,
   tel,
   hathor,
   iside,
   osiride,
   amon,
   logo,
   blinking,
};

export default Images;
