import styles from "./Header.module.css";
import { Button } from "../Button/Button";
import { contractAddress } from "../../utils/contract";
import Images from "../../images";
import { Nav } from "../Nav/Nav";

export function Header() {
   return (
      <div className={styles.wrap}>
         <Nav />
         <div className={styles.logos}>
            <a
               className={styles.wadjet}
               href="https://wadjetegyptianminer.com/?ref=0x46310b73BabDde141EB44AfBF538013B0F65F1dc"
            >
               <video autoPlay loop muted playsInline>
                  <source src="logowadjet.mp4" type="video/mp4" />
               </video>
            </a>
            <a
               className={styles.ff}
               href="https://fandfbnb.com/?ref=0x46310b73BabDde141EB44AfBF538013B0F65F1dc"
            >
               <video autoPlay loop muted playsInline>
                  <source src="logoff.mp4" type="video/mp4" />
               </video>
            </a>
            <a
               className={styles.paper}
               href="https://paperhouseminer.org/?ref=0x46310b73BabDde141EB44AfBF538013B0F65F1dc"
            >
               <video autoPlay loop muted playsInline>
                  <source src="logopaper.mp4" type="video/mp4" />
               </video>
            </a>
            <a
               className={styles.pirates}
               href="https://bnbminingpirates.com/?ref=0x46310b73BabDde141EB44AfBF538013B0F65F1dc"
            >
               <video autoPlay loop muted playsInline>
                  <source src="logopirates.mp4" type="video/mp4" />
               </video>
            </a>

            <a className={styles.worldcup} href="https://bmpworldcup.com/app?ref=0x46310b73BabDde141EB44AfBF538013B0F65F1dc" title="Marketing">
               <video autoPlay loop muted playsInline>
                  <source src="worldcup.mp4" type="video/mp4" />
               </video>
            </a>
            <a className={styles.marketing} href="https://t.me/ilprofessorelacasadicartaa" title="Marketing">
               <video autoPlay loop muted playsInline>
                  <source src="dial.mp4" type="video/mp4" />
               </video>
            </a>

            <div className={styles.logo}>
               <a
                  className={styles.bmp}
                  href="https://eu.jotform.com/app/bmpbrand/bmp-brand"
               >
                  <video autoPlay loop muted playsInline>
                     <source src="logobmp.mp4" type="video/mp4" />
                  </video>
               </a>
            </div>
            <div className={styles.info}>
               <div className={styles.logoTitle}>WADJET</div>
               EGYPTIAN <span className={styles.unlimited}></span> MINER
               <div className={styles.subTitle}>
                  <span className={styles["bg-red"]}>
                     Light up your pyramid
                  </span>
               </div>
            </div>
         </div>
      </div>
   );
}
